import { inject } from '@angular/core';
import { patchState, signalStore, type, withHooks, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { SelectEntityId, setAllEntities, withEntities } from '@ngrx/signals/entities';
import { map, pipe, switchMap, tap } from 'rxjs';

import { ConfigService } from './config.service';
import { FeatureFlag, FeatureFlagCode } from '../models/configs.model';

const selectId: SelectEntityId<FeatureFlag> = (featureFlag) => featureFlag.code;

export const ConfigStore = signalStore(
  { providedIn: 'root' },
  withState({
    isLoaded: false,
  }),
  withEntities(
    { entity: type<FeatureFlag>() },
  ),
  withMethods((store) => {
    const configService = inject(ConfigService);
    return {
      loadConfig: rxMethod<void>(pipe(
        switchMap(() => configService.getFeatureFlags()),
        tap((featureFlags: FeatureFlag[]) => {
          patchState(store, setAllEntities(featureFlags, {selectId}));
          patchState(store, (state) => ({isLoaded: true}));
        }),
      )),
      isEnabled: rxMethod<FeatureFlagCode>(pipe(
        map((featureName : FeatureFlagCode) => {
          return store.entities().find(feature => feature.code === featureName)?.enabled || false;
        }),
        tap(value => console.log(value)),
      ))
    }
  }),
  withHooks({
    onInit(store) {
      store.loadConfig();
    },
  })
);

export type ConfigStore = InstanceType<typeof ConfigStore>;

