import { CanActivateFn, Router, Routes } from '@angular/router';
import { computed, inject } from '@angular/core';
import { ConfigStore } from './shared/services/config-store';
import { FEATURE_FLAG } from './shared/models/configs.model';
import { toObservable } from '@angular/core/rxjs-interop';
import { filter, map } from 'rxjs';


export const reportFeatureFlagGuard: CanActivateFn = () => {
    const config: ConfigStore = inject(ConfigStore);
    const router: Router = inject(Router);
    const isFlagEnabled$ = toObservable(computed(() => config.entities().find(feature => feature.code === FEATURE_FLAG.REPORT)?.enabled));
    return isFlagEnabled$.pipe(
        filter(enabled => enabled != undefined),
        map(enabled => { 
            return enabled || router.createUrlTree(['']);
        }),
    ); 
};

export const routes: Routes = [
    { path: '',   redirectTo: '/map', pathMatch: 'full' },
    {
        path: 'map',
        loadComponent: () => import('./map/map.component').then(m => m.MapComponent),
    },
    {
        path: 'awos',
        loadComponent: () => import('./awos/awos.component').then(m => m.AwosComponent),
    },
    {
        path: 'alerts',
        loadComponent: () => import('./alerts/alerts.component').then(m => m.AlertsComponent),
    },
    {
        path: 'stations',
        loadComponent: () => import('./stations/stations.component').then(m => m.StationsComponent),
    },
    {
        path: 'reports',
        canActivate: [reportFeatureFlagGuard],
        loadComponent: () => import('./reports/reports.component').then(m => m.ReportsComponent),
        children: [
            {
                path: 'synop',
                loadComponent: () => import('./reports/synop/hourly-synop/hourly-synop.component').then(m => m.HourlySynopComponent),
            },
            {
                path: 'climat',
                loadComponent: () => import('./shared/layout/wip/wip.component').then(m => m.WipComponent),
            },
            {
                path: 'climat/:id',
                loadComponent: () => import('./shared/layout/wip/wip.component').then(m => m.WipComponent),
            },
            {
                path: 'metar',
                loadComponent: () => import('./shared/layout/wip/wip.component').then(m => m.WipComponent),
            },
            {
                path: 'metar/:id',
                loadComponent: () => import('./shared/layout/wip/wip.component').then(m => m.WipComponent),
            },
            {
                path: 'settings',
                loadComponent: () => import('./shared/layout/wip/wip.component').then(m => m.WipComponent),
            },
            {
                path: '',
                redirectTo: 'synop', pathMatch: 'full',
            }
        ]
    },
    // {
    //     path: 'wis2',
    //     loadComponent: () => import('./wis2/wis2.component').then(m => m.Wis2Component),
    //     children: [
    //         {
    //             path: 'storage',
    //             loadComponent: () => import('./wis2/storage/wis2-storage.component').then(m => m.Wis2StorageComponent),
    //         },
    //         {
    //             path: 'settings',
    //             loadComponent: () => import('./wis2/settings/wis2-settings.component').then(m => m.Wis2SettingsComponent),
    //         },
    //         {
    //             path: '',
    //             redirectTo: 'storage', pathMatch: 'full',
    //         }
    //     ]
    // },
];
