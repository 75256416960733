import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { API_URL } from 'src/environments/url';
import { FeatureFlag } from '../models/configs.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  http = inject(HttpClient);

  getFeatureFlags(): Observable<FeatureFlag[]> {
    return this.http.get<FeatureFlag[]>(API_URL.FEATURE_FLAGS).pipe(
      shareReplay({bufferSize: 1, refCount: true}),
    );
  }

}

